import React, { useEffect, useState } from "react";
import { getCharacterFeatures } from "../../api/APIManager";

const CharacterCard = (props) => {
  const character = props.character;

  const [books, setBooks] = useState([]);

  const avatar = () => {
    var image = character.character_sex
      ? `avatar-placeholder-${character.character_sex.toLowerCase()}`
      : null;
    image += character.character_sex === "Male" ? ".png" : ".jpg";
    return require(`../../assets/characters/${image}`);
  };

  const bookItemLinks = books.map((book) => {
    const image = require(`../../assets/books/${book.book_cover_image}`);

    return (
      <div key={book.book_id} style={styles.bookLinkStyle}>
        <button
          type="button"
          style={{
            height: 120,
            width: 80,
            backgroundImage: "url('" + image + "')",
            backgroundRepeat: "center center no-repeat",
            backgroundSize: 80,
          }}
          id={book.book_id}
        />
      </div>
    );
  });

  useEffect(() => {
    getCharacterFeatures(
      { characterid: character.character_id },
      (character) => {
        setBooks(character);
      }
    );
  }, [character]);

  return (
    <div className="col-lg-6 col-md-6 mb-4">
      <div className="card h-100">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-4">
              <img src={avatar()} alt="" width="125px" />
            </div>
            <div className="col-lg-8" style={{ textAlign: "left" }}>
              <h3 style={styles.nameTitleStyle}>{character.character_name}</h3>
              {character.character_group}
              <br />
              {character.character_sex}
              <br />
              {`${character.character_age} years old`}
            </div>
          </div>
          <div className="row">
            <br />
            <span style={styles.bioSectionStyle}>
              {character.character_bio}
            </span>
          </div>
        </div>
        <div className="card-footer" style={styles.cardFooterStyle}>
          <h5 style={styles.footerTitleStyle}>Appears in:</h5>
          <br />
          <div className="row">
            {bookItemLinks.length > 0 ? (
              bookItemLinks
            ) : (
              <div style={styles.futureTitleStyle}>Future Titles</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  bioSectionStyle: {
    marginLeft: 25,
    marginRight: 25,
    textAlign: "left",
  },
  bookLinkStyle: {
    paddingRight: "10px",
    textAlign: "left",
  },
  cardFooterStyle: {
    minHeight: 200,
  },
  footerTitleStyle: {
    color: "#03b0e8",
    fontWeight: "bold",
    textAlign: "left",
  },
  futureTitleStyle: {
    textAlign: "left",
    fontSize: 30,
  },
  nameTitleStyle: {
    color: "#03b0e8",
  },
};

export default CharacterCard;

import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BlogDetailCard from "../components/cards/BlogDetailCard";
import ParallaxDiv from "../components/ParallaxDiv";
import { getBlogEntry } from "../api/APIManager";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const BlogEntry = () => {
  const [blog, setBlog] = useState(null);
  let query = useQuery();

  useEffect(() => {
    getBlogEntry({ blogid: query.get("blogid") }, (blog) => {
      if (blog.length > 0) {
        setBlog(blog[0]);
      }
    });
  }, [query]);

  return (
    <ParallaxDiv backgroundChoice={3}>
      <div style={styles.parallaxContentStyle}>
        <div className="container" style={styles.containerStyle}>
          {blog ? <BlogDetailCard blog={blog} detail={true} /> : null}
        </div>
      </div>
    </ParallaxDiv>
  );
};

export default BlogEntry;

const styles = {
  containerStyle: {
    minHeight: 500,
    marginTop: 100,
    textAlign: "left",
  },
  parallaxContentStyle: {
    paddingTop: 50,
    paddingBottom: 200,
  },
};

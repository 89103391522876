import React from 'react';
import ReactGA from 'react-ga';
import { createBrowserHistory } from 'history';
import { Router, Switch, Route, Link } from 'react-router-dom';
import './App.css';
import AboutMe from './views/AboutMe';
import Blog from './views/Blog';
import BlogEntry from './views/BlogEntry';
import Books from './views/Books';
import ContactMe from './views/ContactMe';
import Home from './views/Home';
import SearchForSelf from './views/SearchForSelf';
import Footer from './components/Footer';

const history = createBrowserHistory();

function App() {
  initializeReactGA();
  
  return (
    <Router history={history}>
      <div className="App">        
        <nav className="navbar navbar-expand-lg navbar-dark info-color-dark ">{/* fixed-top */}
          <div className="container">
            
            <div className="navbar-header">
              <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbar" aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
              </button>
              {/* <a class="navbar-brand" href="#"><h1 style={styles.nameHeaderStyle}>SELISA LYN JOHNSON</h1></a> */}
              <Link className="navbar-brand" to='/'><h1 style={styles.nameHeaderStyle}>SELISA LYN JOHNSON</h1></Link>
            </div>

            <div id="navbar" className="collapse navbar-collapse">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link className="nav-link" to="/">Home</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/books">Books</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/blog">Blog</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/searchforself">Search for Self</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/aboutme">About Me</Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/contactme">Contact Me</Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
        <Switch>
          <Route path="/aboutme">
            <AboutMe />
          </Route>
          <Route path="/blog">
            <Blog />
          </Route>
          <Route path="/blogentry">
            <BlogEntry />
          </Route>
          <Route path="/books">
            <Books />
          </Route>
          <Route path="/contactme">
            <ContactMe />
          </Route>
          <Route path="/searchforself">
            <SearchForSelf />
          </Route>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <Footer />
      </div>
    </Router>
  );
}

const styles = {
  nameHeaderStyle: {
    textAlign: 'left',
    color: 'white',
    fontFamily: 'Charlesworth',
    fontWeight: 'bold',
    fontSize: 22,
    letterSpacing: 2,
    // marginLeft: 15,
    textShadow: '-1px 1px 10px black'
  }
}

function initializeReactGA() {
  ReactGA.initialize('UA-159416277-1');

  // Initialize google analytics page view tracking
  history.listen(location => {
    ReactGA.set({ page: location.pathname }); // Update the user's current page
    ReactGA.pageview(location.pathname); // Record a pageview for the given page
  });
}

export default App;

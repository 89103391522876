import React, { useEffect, useState } from "react";
import { PageTitle } from "../components/texts/PageTitle";
import BlogCard from "../components/cards/BlogCard";
import ParallaxDiv from "../components/ParallaxDiv";
import { getBlog } from "../api/APIManager";

const Blog = () => {
  const [blog, setBlog] = useState([]);

  const blogItems = blog.map((blog) => {
    return <BlogCard key={blog.blog_id} blog={blog} />;
  });

  useEffect(() => {
    getBlog((blog) => {
      setBlog(blog);
    });
  }, []);

  return (
    <ParallaxDiv backgroundChoice={3}>
      <div style={styles.parallaxContentStyle}>
        <PageTitle>Blog</PageTitle>
        <div className="container" style={styles.containerStyle}>
          <div className="row">{blogItems}</div>
        </div>
      </div>
    </ParallaxDiv>
  );
};

export default Blog;

const styles = {
  containerStyle: {
    minHeight: 500,
    marginTop: 100,
    textAlign: "left",
  },
  parallaxContentStyle: {
    paddingTop: 50,
    paddingBottom: 200,
  },
};

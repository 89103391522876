import React from "react";
import {
  MDBModal,
  MDBModalHeader,
  MDBModalBody,
  MDBModalFooter,
} from "mdbreact";
import NumberFormat from "react-number-format";

const BookInfoModal = (props) => {
  const { bookInfo, vendorInfo } = props.book;

  const bookImage = bookInfo.book_cover_image
    ? require(`../assets/books/${bookInfo.book_cover_image}`)
    : null;

  const vendorImage = (vendor) => {
    switch (vendor) {
      case "Amazon":
        return require("../assets/vendors/amazon.jpeg");
      case "Apple Books":
        return require("../assets/vendors/apple.png");
      case "Barnes & Noble":
        return require("../assets/vendors/barnesnoble.jpg");
      case "Google Books":
        return require("../assets/vendors/googleplay.png");
      case "Direct Sale":
        return require("../assets/vendors/paypal.png");
      default:
        break;
    }
  };

  const vendorLinks = (bookformat, bookcost) => {
    const links = vendorInfo
      .filter((vendor) => vendor.book_vendor_formats === bookformat)
      .map((vendor) => {
        return (
          <a
            key={vendor.book_vendor_id}
            href={vendor.book_vendor_item_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{ marginRight: 15 }}
              src={vendorImage(vendor.book_vendor_name)}
              alt={vendor.vendor_name}
              width="75px"
            />
          </a>
        );
      });

    if (links.length > 0) {
      return (
        <div style={styles.vendorLinksSectionStyle}>
          <div style={styles.bookFormatStyle}>
            {bookformat.toUpperCase()}
            {" - "}
            <NumberFormat
              value={bookcost}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
              decimalScale={2}
              fixedDecimalScale={true}
            />
            :
          </div>
          <div>{links}</div>
        </div>
      );
    }
  };

  const vendorPricing = (bookformat) => {
    const prices = vendorInfo.filter(
      (vendor) => vendor.book_vendor_formats === bookformat
    );

    if (prices.length > 0) {
      return prices[0].book_cost;
    }
  };

  return (
    <MDBModal
      id="centralModalInfo"
      className="modal-dialog modal-lg modal-notify modal-info"
      isOpen={props.show}
      toggle={props.toggleModal}
    >
      <MDBModalHeader toggle={props.toggleModal}>
        <p className="heading lead">Book Info</p>
      </MDBModalHeader>
      <MDBModalBody>
        <div className="row">
          <div className="col-md-4">
            <img src={bookImage} id="book_image" alt="" width="220px" />
          </div>
          <div className="col-md-8" style={{ textAlign: "left" }}>
            <h1 style={styles.bookTitleStyle} name="book_title" id="book_title">
              {bookInfo.book_title}
            </h1>
            <h2 id="book_series">{bookInfo.book_series}</h2>
            <div className="text-left" style={styles.bookDescriptionStyle}>
              <div id="book_description">{bookInfo.book_description}</div>
            </div>
          </div>
        </div>
      </MDBModalBody>
      <MDBModalFooter
        className="justify-content-start"
        style={{ textAlign: "left" }}
      >
        <div>
          <div style={styles.buyTitleStyle}>Where to Buy</div>
          <div>{vendorLinks("ebook", vendorPricing("ebook"))}</div>
          <div>{vendorLinks("paperback", vendorPricing("paperback"))}</div>
        </div>
      </MDBModalFooter>
    </MDBModal>
  );
};

const styles = {
  bookDescriptionStyle: {
    marginTop: 15,
    fontSize: 18,
  },
  bookFormatStyle: {
    fontWeight: "bold",
    fontSize: 14,
  },
  bookTitleStyle: {
    color: "purple",
    fontSize: 40,
    fontWeight: "bold",
  },
  buyTitleStyle: {
    color: "#03b0e8",
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 30,
  },
  vendorLinksSectionStyle: {
    marginTop: 10,
  },
};

export default BookInfoModal;

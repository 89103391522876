import axios from "axios";

// URL Session
export const deleteUrlSession = (url, callback) => {
  axios
    .delete(url)
    .then((response) => {
      return callback(response.data);
    })
    .catch((error) => console.log(error));
};

export const getUrlSession = (url, callback, isBaseData) => {
  isBaseData = isBaseData || false;

  axios
    .get(url)
    .then((response) => {
      const data = response.data;
      if (typeof data.errors !== "undefined") {
        return callback(null);
      }
      const _data = isBaseData ? data : data.data;
      return callback(_data);
    })
    .catch((error) => console.log(error));
};

export const postUrlSession = (url, body, callback) => {
  axios
    .post(url, body)
    .then((response) => {
      return callback(response.data);
    })
    .catch((error) => console.log(error));
};

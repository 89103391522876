import React from 'react';
import BookCard from '../cards/BookCard';

const BookCardSection = (props) => {
    const bookItem = props.books.map((book) => {
        const image = require(`../../assets/books/${book.book_cover_image}`);
        return <BookCard key={book.book_id} book={book} image={image} />       
    });
    
    return (
        <div className='container' style={styles.sectionStyle}>
            <div className='row'>
                {bookItem}
            </div>
        </div>
    )
}

const styles = {
    sectionStyle: {
        marginBottom: 50
    }
}

export default BookCardSection;
import React from 'react';

const Footer = () => {
    
    return (
        <footer style={styles.footerStyle}>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 p-5">
                        <div className="mb-5 flex-center">
                            {/* Facebook */}
                            <a className="fb-ic" href="https://fb.me/selisalynbooks" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-facebook-f fa-lg white-text mr-md-5 mr-3 fa-2x"> </i>
                            </a>
                            {/* Twitter */}
                            <a className="tw-ic" href="https://twitter.com/selisalynbooks" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-twitter fa-lg white-text mr-md-5 mr-3 fa-2x"> </i>
                            </a>
                            {/* Google */}
                            {/* <a className="gplus-ic">
                            <i className="fab fa-google-plus-g fa-lg white-text mr-md-5 mr-3 fa-2x"> </i>
                            </a> */}
                            {/* Linkedin */}
                            {/* <a className="li-ic">
                            <i className="fab fa-linkedin-in fa-lg white-text mr-md-5 mr-3 fa-2x"> </i>
                            </a> */}
                            {/* Instagram */}
                            <a className="ins-ic" href="https://www.instagram.com/selisalynbooks/" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-instagram fa-lg white-text mr-md-5 mr-3 fa-2x"> </i>
                            </a>
                            {/* Pinterest */}
                            {/* <a className="pin-ic" href="https://www.pinterest.com" target="_blank" rel="noopener noreferrer">
                            <i className="fab fa-pinterest fa-lg white-text fa-2x"> </i>
                            </a> */}
                        </div>
                    </div>
                </div>
                <div className="pb-5">
                    <p id="zzz" className="m-0 text-center text-white">Selisa Lyn Johnson</p>
                    <img src={require("../assets/misc/jgpg_logo.png")} alt="JPGP Logo" width="200px" />
                    <p className="m-0 text-center text-white">Copyright &copy; Johnson Global Publishing Group, LLC 2023</p>
                    <p className="m-0 text-center text-white">Developed By Cross Vision Development Studios</p>
                </div>
            </div>
        </footer>
    )
}

const styles = {
    footerStyle: {
        backgroundColor: '#03b0e8',
        height: 380,
        position: 'absolute',
        left:0,
        // bottom:0,
        right:0
    }
}

export default Footer;
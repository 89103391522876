import React, { useState } from "react";
import qs from "qs";
import { PageTitle } from "../components/texts/PageTitle";
import ParallaxDiv from "../components/ParallaxDiv";
import { postContactMe } from "../api/APIManager";

const ContactMe = () => {
  const [contactInfo, setContactInfo] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  onchange = (e) => {
    let newContactInfo = contactInfo;
    newContactInfo[e.target.name] = e.target.value;
    setContactInfo(newContactInfo);
  };

  onsubmit = (e) => {
    e.preventDefault();

    postContactMe(qs.stringify(contactInfo), () => {
      document.getElementById("contact_me_form").reset();
      alert(
        "Thank you for contact me, and I will reply back as soon as possible."
      );
    });
  };

  return (
    <ParallaxDiv backgroundChoice={3}>
      <div style={styles.parallaxContentStyle}>
        <PageTitle>Contact Me</PageTitle>
        <div className="container" style={styles.containerStyle}>
          <form
            id="contact_me_form"
            action=""
            method="post"
            encType="multipart/form-data"
          >
            <div className="form-row">
              <div className="form-group col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="name"
                  placeholder="Name"
                />
              </div>
              <div className="form-group col-md-6">
                <input
                  type="email"
                  className="form-control"
                  name="email"
                  placeholder="Email"
                />
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="phone"
                  placeholder="Phone"
                />
              </div>
              <div className="form-group col-md-6">
                <input
                  type="text"
                  className="form-control"
                  name="subject"
                  placeholder="Subject"
                />
              </div>
            </div>
            <div className="form-group">
              <textarea
                name="message"
                className="form-control"
                placeholder="Type Message Here...."
                required=""
                rows="10"
              ></textarea>
            </div>
            <div className="col-md-12 text-center">
              <input
                type="submit"
                className="btn info-color-dark white-text"
                name="update_post"
                value="Submit Now"
              />
            </div>
          </form>
          <div id="submit_message" style={styles.submitMessageStyle}></div>
        </div>
      </div>
    </ParallaxDiv>
  );
};

export default ContactMe;

const styles = {
  containerStyle: {
    marginTop: 50,
    marginBottom: 50,
    paddingTop: 40,
    paddingBottom: 20,
  },
  pageTitleStyle: {
    marginTop: 100,
  },
  parallaxContentStyle: {
    paddingTop: 50,
    paddingBottom: 200,
  },
  submitMessageStyle: {
    textAlign: "left",
    color: "red",
    marginTop: 20,
  },
};

import React from 'react';

const BookCard = (props) => {
    const { book } = props;

    return (
        <div style={styles.cardStyle}>
            <div style={styles.bodyStyle}>
                <img src={props.image} alt={props.imgTitle} style={styles.imageStyle} />
                <h2 style={styles.titleStyle}>{book.book_title}</h2>
            </div>
            <div className='card-footer'>
                <button 
                    type="button" 
                    className="btn white-text info-color-dark display_modal"
                    id={book.book_id}
                >
                    Find Out More!
                </button>
            </div>
        </div>
    )
}

const styles = {
    cardStyle: {
        display: 'inline-block',
        borderRadius: 5,
        justifiedContent: 'center',
        margin: 15,
        marginLeft: 'auto',
        marginRight: 'auto',
        height: 375,
        width: 255,
        padding: 0,
        backgroundColor: '#FFF',
        WebkitFilter: "drop-shadow(0px 0px 5px #555)",
        filter: "drop-shadow(0px 0px 5px #555)"
    },
    bodyStyle: {
        height: 295,
        backgroundColor: '#FFF'
    },
    footerButtonStyle: {
        height: 44,
        width: 275,
        marginTop: 18,
        marginLeft: 40,
        marginRight: 40,
        color: "#FFF",
        backgroundColor: "#189ACA",
        fontSize: 12,
        fontFamily: "Arial, Helvetica, sans-serif",
        textTransform: "uppercase",
        borderRadius: 5,
        outline: 'none'
    },
    imageStyle: {
        marginTop:25,
        marginLeft: 65,
        marginRight: 65,
        width: 125,
        height: 185,
    },
    titleStyle: {
        fontSize: 20,
        textAlign: 'center',
        color: 'purple',
        fontFamily: "Arial, Helvetica, sans-serif",
        marginTop: 30
    }
};

export default BookCard;
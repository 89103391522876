import React, { useEffect, useState } from "react";
import BookDetailCardSection from "../components/sections/BookDetailCardSection";
import { PageTitle } from "../components/texts/PageTitle";
import ParallaxDiv from "../components/ParallaxDiv";
import { getBooks } from "../api/APIManager";

const Books = () => {
  const [books, setBooks] = useState([]);

  useEffect(() => {
    getBooks({}, (books) => {
      setBooks(books);
    });
  }, []);

  return (
    <ParallaxDiv backgroundChoice={4}>
      <div style={styles.parallaxContentStyle}>
        <PageTitle>Books</PageTitle>
        <div className="container" style={styles.containerStyle}>
          <BookDetailCardSection books={books} />
        </div>
      </div>
    </ParallaxDiv>
  );
};

export default Books;

const styles = {
  blueTextStyle: {
    color: "#03b0e8",
    fontWeight: "bold",
  },
  containerStyle: {
    minHeight: 500,
    marginTop: 100,
  },
  parallaxContentStyle: {
    paddingTop: 50,
    paddingBottom: 200,
  },
  promotionTextStyle: {
    color: "#8B49D5",
    fontWeight: "bold",
    textAlign: "left",
  },
};

import React from 'react';
import CharacterCard from '../cards/CharacterCard';

const CharacterCardSection = (props) => {
    const characterItem = props.characters.map((character) => {
        //const image = require(`../assets/books/${book.book_cover_image}`);
        return <CharacterCard key={character.character_id} character={character} />       
    });
    
    return (
        <div className='container' style={styles.sectionStyle}>
            <div className='row text-center'>
                {characterItem}
            </div>
        </div>
    )
}

const styles = {
    sectionStyle: {
        paddingTop: 20
    }
}

export default CharacterCardSection;
import React from 'react';
import { Link } from "react-router-dom";
import { dateCreate, dayMonth } from '../../lib/utils'

const BlogCard = (props) => {
    const { blog } = props;
    
    const year = dateCreate(blog.blog_entry_date).getUTCFullYear().toString()
    const title = blog.blog_title
    const tags = blog.blog_tags
    const blogEntryUrl = `/blogentry?blogid=${blog.blog_entry_id}`

    return (
        <div className="col-md-6" style={styles.cardStyle}>
            <div className="row">
                <div style={styles.dateSectionStyle}>
                    <div style={styles.dayMonthStyle}>{dayMonth(blog.blog_entry_date)}</div>
                    <div style={styles.yearStyle}>{year}</div>
                </div>
                <div style={styles.contentStyle}>
                    <div style={styles.blogTitleStyle}>
                        <Link to={blogEntryUrl}>{title}</Link>
                    </div>
                    <div style={styles.tagStyle}>
                        {tags}
                    </div>
                </div> 
            </div>
        </div>
    )
}

const styles = {
    blogTitleStyle: {
        textAlign: 'left',
        paddingTop: 1,
        paddingRight: 10,
        paddingBottom: 5,
        paddingLeft: 10,
        fontSize: 25,
    },
    cardStyle: {
        margin: 20,
        minHeight: 120,
        maxWidth: 515,
        backgroundColor: '#FFF',
        WebkitFilter: "drop-shadow(0px 0px 5px #555)",
        filter: "drop-shadow(0px 0px 5px #555)"
    },
    contentStyle: {
        width: '80%',
    },
    dayMonthStyle: {
        textAlign: 'right',
        color: 'purple',
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: -8
    },
    dateSectionStyle: {
        height: 100,
        width: 70,
        padding: 5,
    },
    tagStyle: {
        textAlign: 'left',
        width: '200',
        paddingLeft: 10,
        fontSize: 13,
        color: '#03b0e8',
    },
    yearStyle: {
        textAlign: 'right',
        fontSize: 14,
    }
}

export default BlogCard;
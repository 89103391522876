export const WEB_DOMAIN = process.env.REACT_APP_DOMAIN_PROD;

export const API_PATH = () => {
  let path = "/services/bookservice.php";

  return `${WEB_DOMAIN}${path}`;
};

export const dateCreate = (dt) => {
  return new Date(dt.replace(/-/g, "/"));
};

export const dayMonth = (dt) => {
  let date = dateCreate(dt);

  const shortMonths = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const shortMonth = shortMonths[date.getMonth()].toUpperCase();
  return `${date.getUTCDate()}/${shortMonth}`;
};

import { getUrlSession, postUrlSession } from "./UrlSession";

const BaseApiPath = (qtype) => {
  const API_PATH =
    "https://www.selisalynjohnsonbooks.com/services/bookservice.php";
  return `${API_PATH}?qtype=${qtype}`;
};

const getBlog = (callback) => {
  let url = `${BaseApiPath("blog")}`;
  getUrlSession(url, callback, true);
};

const getBlogEntry = (params, callback) => {
  const url = `${BaseApiPath("blog")}&blog_id=${params.blogid}`;
  getUrlSession(url, callback, true);
};

const getBooks = (params, callback) => {
  let url = `${BaseApiPath("books")}`;

  if (params.count !== undefined) {
    url += `&count=${params.count}`;
  }

  if (params.latest !== undefined) {
    url += `&latest=${params.latest}`;
  }

  getUrlSession(url, callback, true);
};

const getCharacters = (callback) => {
  const url = `${BaseApiPath("characters")}`;
  getUrlSession(url, callback, true);
};

const getCharacterFeatures = (params, callback) => {
  const url = `${BaseApiPath("characterfeatures")}&character_id=${
    params.characterid
  }`;
  getUrlSession(url, callback, true);
};

const getLatestBlog = (callback) => {
  const url = `${BaseApiPath("blog")}&latest=true`;
  getUrlSession(url, callback, true);
};

const postContactMe = (body, callback) => {
  const url = `${BaseApiPath("contactme")}`;
  postUrlSession(url, body, callback);
}

export {
  getBlog,
  getBlogEntry,
  getBooks,
  getCharacters,
  getCharacterFeatures,
  getLatestBlog,
  postContactMe
};

import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_PATH } from "../lib/utils";
import BookInfoModal from "../components/BookInfoModal";
import CharacterCardSection from "../components/sections/CharacterCardSection";
import { PageTitle } from "../components/texts/PageTitle";
import ParallaxDiv from "../components/ParallaxDiv";
import { getCharacters } from "../api/APIManager";

const SearchForSelf = () => {
  const [characters, setCharacters] = useState([]);
  const [showBookModal, setShowBookModal] = useState(false);
  const [bookModal, setBookModal] = useState({
    bookInfo: {},
    vendorInfo: [],
  });

  const toggleModal = () => {
    setShowBookModal(!showBookModal);
  };

  useEffect(() => {
    getCharacters((characters) => {
      setCharacters(characters);
    });

    document.body.addEventListener("click", async (e) => {
      const book_id = e.target.id;
      const type = e.target.type;

      if (book_id !== "" && type === "button") {
        const [firstResponse, secondResponse] = await Promise.all([
          axios.get(
            `${API_PATH()}?qtype=bookmodal&book_id=${book_id}`
          ),
          axios
            .get(
              `${API_PATH()}?qtype=bookvendorsinfo&book_id=${book_id}`
            )
            .catch((error) => console.log(error)),
        ]);

        setBookModal({
          bookInfo: firstResponse.data[0],
          vendorInfo: secondResponse.data,
        });

        setShowBookModal(true);
      }
    });
  }, []);

  return (
    <ParallaxDiv backgroundChoice={1}>
      <div style={styles.parallaxContentStyle}>
        <PageTitle>Search for Self</PageTitle>
        <BookInfoModal
          book={bookModal}
          show={showBookModal}
          toggleModal={toggleModal}
        />
        <div className="container" style={styles.containerStyle}>
          <header className="jumbotron my-4">
            <div className="row">
              <div className="col-md-4 text-center">
                <h3 style={styles.blueTextStyle}>Central High School</h3>
                <img
                  src={require("../assets/books/searchforself.jpg")}
                  alt="Search for Self"
                  width="200px"
                />
              </div>
              <div className="col-md-8" style={{ textAlign: "center" }}>
                <p style={styles.descriptionStyle}>
                  From the{" "}
                  <span style={styles.blueTextStyle}>Fall of 1988</span> until
                  the <span style={styles.blueTextStyle}>Spring of 1990</span>,
                  a group of kids attends Central high school, experiences the
                  struggles of being a teenager. The recent closing of{" "}
                  <span style={styles.blueTextStyle}>Lincoln High School</span>{" "}
                  on the southside of town causes the transfer of over three
                  hundred black students to a dominantly white,{" "}
                  <span style={styles.blueTextStyle}>Central High School</span>.
                  Making for an even greater need to climb higher up the
                  popularity ladder, just to fit in.
                  <br />
                  <br />
                  If the cultural clash on both sides wasn’t enough, the town’s
                  biggest employer, The Mill, laid off hundreds of workers after
                  a big strike. Sometimes, it’s like no matter what, nothing
                  ever works out the way you want it to, and you only spend most
                  of your time dealing with everything. The kids in the{" "}
                  <span style={styles.blueTextStyle}>
                    “Search for Self”
                  </span>{" "}
                  series only try to cope with things and not get caught up in
                  all the despair they face
                  <br />
                  <br />
                  The <span style={styles.blueTextStyle}>series</span> focuses
                  on allowing the reader to become a part of the adventures of
                  various students from all walks of life as they deal with
                  their emotions, friendships, and growth as individuals.
                </p>
              </div>
            </div>
          </header>

          <h2 className="display-5 purple-text" align="center">
            Student Body
          </h2>
          <div className="row">
            <CharacterCardSection characters={characters} />
          </div>
        </div>
      </div>
    </ParallaxDiv>
  );
};

const styles = {
  blueTextStyle: {
    color: "#03b0e8",
    fontWeight: "bold",
  },
  containerStyle: {
    marginTop: 100,
  },
  descriptionStyle: {
    textAlign: "left",
  },
  parallaxContentStyle: {
    paddingTop: 50,
    paddingBottom: 200,
  },
};

export default SearchForSelf;

import React from 'react';

const PageTitle = (props) => {
    var classString = "display-2 "
    classString += props.color ? `${props.color}-text` : 'white-text';
    
    return (
        <h1 className={classString}  style={styles.titleStyle}>{props.children}</h1>
    )
};

const SubTitle = (props) => {
    var classString = "display-4 "
    classString += props.color ? `${props.color}-text` : 'white-text';
    
    return (
        <h1 className={classString}  style={styles.titleStyle}>{props.children}</h1>
    )
};

export { PageTitle, SubTitle };

const styles = {
    titleStyle: {
        textAlign: 'center',
        textShadow: '-1px 1px 10px #000'
    }
}
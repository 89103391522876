import React from 'react';
import { Link } from "react-router-dom";
import { dateCreate, dayMonth } from '../../lib/utils'

const BlogDetailCard = (props) => {
    const { blog, detail } = props;

    const year = dateCreate(blog.blog_entry_date).getUTCFullYear().toString()
    const blogEntryUrl = `/blogentry?blogid=${blog.blog_entry_id}`
    const blogContent = () => {
        const stringMax = 1470
        if (detail || blog.blog_content.length < stringMax ) {
            return blog.blog_content
        } else {
            return (
                <div>
                    {`${blog.blog_content.substring(0,stringMax)}...`}
                    <Link to={blogEntryUrl}>more</Link>
                </div>
            )
        }
    }

    return (
        <div style={styles.cardStyle}>
            <div className="row">
                <div style={styles.dateSectionStyle}>
                    <div style={styles.dayMonthStyle}>{dayMonth(blog.blog_entry_date)}</div>
                    <div style={styles.yearStyle}>{year}</div>
                </div>
                <div>
                    <div style={styles.blogTitleStyle}>
                        {blog.blog_title}
                    </div>
                    <div style={styles.contentSectionStyle}>
                        {blogContent()}
                    </div>
                    <div style={styles.tagStyle}>
                        {blog.blog_tags}
                    </div>
                </div>
            </div>
        </div>
    )
}

const styles = {
    blogTitleStyle: {
        textAlign: 'left',
        wordWrap: 'break-word',
        paddingTop: 0,
        paddingRight: 10,
        paddingBottom: 5,
        paddingLeft: 10,
        fontSize: 33,
        fontWeight: 'bold',
        color: '#03b0e8',
    },
    cardStyle: {
        backgroundColor: '#FFF',
        width: '100%',
        textAlign: 'left',
        padding: 20,
        WebkitFilter: "drop-shadow(0px 0px 5px #555)",
        filter: "drop-shadow(0px 0px 5px #555)"
    },
    contentSectionStyle: {
        wordWrap: 'break-word',
        paddingTop: 0,
        paddingLeft: 10,
        paddingRight: 10,
        maxWidth: 1000,
        fontSize: 18,
        marginTop: -10
    },
    dayMonthStyle: {
        textAlign: 'right',
        color: 'purple',
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: -8
    },
    dateSectionStyle: {
        height: 100,
        width: 100,
        paddingTop: 8,
    },
    tagStyle: {
        textAlign: 'left',
        wordWrap: 'break-word',
        width: '200',
        paddingLeft: 10,
        fontSize: 13,
        color: '#03b0e8',
    },
    yearStyle: {
        textAlign: 'right',
        fontSize: 14,
    }
}

export default BlogDetailCard;
import React from 'react';

const ParallaxDiv = (props) => {
    const getBackgroundImage = choice => {
        switch (choice) {
            case 1:
                return require('../assets/backgrounds/background1.jpg');
            case 2:
                return require('../assets/backgrounds/background2.jpg');
            case 3:
                return require('../assets/backgrounds/background3.jpg');
            case 4:
                return require('../assets/backgrounds/background4.jpg');
            default:
                return null
        }
    }

    const backgroundImage = getBackgroundImage(props.backgroundChoice)
    const FOOTER_HEIGHT = 380
    const styles = {
        parallax: {
            backgroundImage: "url('"+backgroundImage+"')",
            minHeight: window.innerHeight - FOOTER_HEIGHT,
            backgroundAttachment: 'fixed',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
        }
    }

    return (
        <div style={styles.parallax}>{props.children}</div>
    )
};



export default ParallaxDiv;
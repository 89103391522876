import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_PATH } from "../lib/utils";
import BlogDetailCard from "../components/cards/BlogDetailCard";
import BookCardSection from "../components/sections/BookCardSection";
import BookInfoModal from "../components/BookInfoModal";
import { SubTitle } from "../components/texts/PageTitle";
import ParallaxDiv from "../components/ParallaxDiv";
import { getBooks, getLatestBlog } from "../api/APIManager";

const Home = () => {
  // const defaultState = {
  //   book: {
  //     book_id: "",
  //     book_title: "",
  //     book_series: "",
  //     book_description: "",
  //     book_cover_image: "",
  //     book_first_published_date: "",
  //     book_active: "",
  //     vendor_info: [],
  //   },
  // };

  const [books, setBooks] = useState([]);
  const [blog, setBlog] = useState(null);
  const [showBookModal, setShowBookModal] = useState(false);
  const [bookModal, setBookModal] = useState({
    bookInfo: {},
    vendorInfo: [],
  });

  const toggleModal = () => {
    setShowBookModal(!showBookModal);
  };

  useEffect(() => {
    getBooks({ count: 4, latest: true }, (books) => {
      setBooks(books);
    });

    getLatestBlog((blogs) => {
      if (blogs.length > 0) {
        setBlog(blogs[0]);
      }
    });

    document.body.addEventListener("click", async (e) => {
      const book_id = e.target.id;
      const type = e.target.type;

      if (book_id !== "" && type === "button") {
        const [firstResponse, secondResponse] = await Promise.all([
          axios.get(`${API_PATH()}?qtype=bookmodal&book_id=${book_id}`),
          axios
            .get(`${API_PATH()}?qtype=bookvendorsinfo&book_id=${book_id}`)
            .catch((error) => console.log(error)),
        ]);

        setBookModal({
          bookInfo: firstResponse.data[0],
          vendorInfo: secondResponse.data,
        });

        setShowBookModal(true);
      }
    });
  }, []);

  return (
    <ParallaxDiv backgroundChoice={1}>
      <div className="container" style={styles.containerStyle}>
        <header className="jumbotron my-4">
          <div className="row">
            <div className="col-md-7" style={{ textAlign: "left" }}>
              <img
                style={styles.authorImageStyle}
                src={require("../assets/misc/slj_author.jpg")}
                align="left"
                alt="Author"
              />
              <p style={styles.headerTextStyle}>
                Welcome to my website! I am Selisa and have been writing for
                over 40 years, but just now getting the chance to accomplish my
                life long dream to have my work published. Please checkout my
                library of books and keep in touch to find out about my future
                projects.
              </p>
            </div>
            <div className="col-md-5">
              <img
                className="rounded img-fluid"
                src={require("../assets/misc/topImage.jpg")}
                alt="When the laugh stop"
              />
            </div>
          </div>
        </header>
      </div>

      <SubTitle>Latest Titles</SubTitle>
      <BookInfoModal
        id="book-info-modal"
        book={bookModal}
        show={showBookModal}
        toggleModal={toggleModal}
      />
      <BookCardSection books={books} />
      <SubTitle>Latest Blog Entry</SubTitle>
      <div className="container" style={styles.blogSectionStyle}>
        {blog ? <BlogDetailCard blog={blog} /> : null}
      </div>
    </ParallaxDiv>
  );
};

const styles = {
  authorImageStyle: {
    height: 200,
    width: 200,
    borderRadius: "50%",
    marginRight: 30,
  },
  blogSectionStyle: {
    // Height: 500,
    marginTop: 25,
    paddingBottom: 50,
  },
  containerStyle: {
    paddingTop: 30,
    marginBottom: 50,
  },
  headerTextStyle: {
    textAlign: "left",
    fontSize: 24,
  },
  nameHeaderStyle: {
    textAlign: "left",
    fontFamily: "Charlesworth",
    fontWeight: "bold",
    fontSize: 54,
    paddingLeft: 15,
  },
  sectionHeaderStyle: {
    textAlign: "center",
    fontWeight: "bold",
    marginTop: 100,
  },
};

export default Home;

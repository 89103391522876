import React from "react";
import NumberFormat from "react-number-format";

const BookDetailCard = (props) => {
  const { book } = props;

  const bookImage = book.book_cover_image
    ? require(`../../assets/books/${book.book_cover_image}`)
    : null;

  const vendorImage = (vendor) => {
    switch (vendor) {
      case "Amazon":
        return require("../../assets/vendors/amazon.jpeg");
      case "Apple Books":
        return require("../../assets/vendors/apple.png");
      case "Barnes & Noble":
        return require("../../assets/vendors/barnesnoble.jpg");
      case "Google Books":
        return require("../../assets/vendors/googleplay.png");
      case "Direct Sale":
        return require("../../assets/vendors/paypal.png");
      default:
        break;
    }
  };

  const vendorLinks = (bookformat, bookcost) => {
    const links = book.vendor_info
      .filter((vendor) => vendor.book_vendor_formats === bookformat)
      .map((vendor) => {
        return (
          <a
            key={vendor.book_vendor_id}
            href={vendor.book_vendor_item_url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              style={{ marginRight: 15 }}
              src={vendorImage(vendor.book_vendor_name)}
              alt={vendor.vendor_name}
              width="75px"
            />
          </a>
        );
      });

    if (links.length > 0) {
      return (
        <div style={styles.vendorLinksSectionStyle}>
          <div style={styles.bookFormatStyle}>
            {bookformat.toUpperCase()}
            {" - "}
            <NumberFormat
              value={bookcost}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
              decimalScale={2}
              fixedDecimalScale={true}
            />
            :
          </div>
          <div>{links}</div>
        </div>
      );
    }
  };

  const vendorPricing = (bookformat) => {
    const prices = book.vendor_info.filter(
      (vendor) => vendor.book_vendor_formats === bookformat
    );

    if (prices.length > 0) {
      return prices[0].book_cost;
    }
  };

  return (
    <div style={styles.cardStyle}>
      <div className="row">
        <div className="col-md-4">
          <img
            src={bookImage}
            id="book_image"
            alt=""
            style={styles.bookImageStyle}
          />
        </div>
        <div className="col-md-8" style={{ textAlign: "left" }}>
          <h1 style={styles.bookTitleStyle} name="book_title" id="book_title">
            {book.book_title}
          </h1>
          <h2 id="book_series">{book.book_series}</h2>
          <div className="text-left" style={styles.bookDescriptionStyle}>
            <div id="book_description">{book.book_description}</div>
          </div>
          <div>
            <div style={styles.buyTitleStyle}>Where to Buy</div>
            {vendorLinks("ebook", vendorPricing("ebook"))}
            {vendorLinks("paperback", vendorPricing("paperback"))}
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  bookDescriptionStyle: {
    marginTop: 15,
    fontSize: 18,
  },
  bookFormatStyle: {
    fontWeight: "bold",
    fontSize: 16,
    color: "purple",
  },
  bookImageStyle: {
    minWidth: "99%",
    maxWidth: "99%",
  },
  bookTitleStyle: {
    color: "purple",
    fontSize: 50,
    fontWeight: "bold",
  },
  buyTitleStyle: {
    color: "#03b0e8",
    fontSize: 20,
    fontWeight: "bold",
    marginTop: 30,
  },
  cardStyle: {
    backgroundColor: "white",
    marginBottom: 25,
    paddingBottom: 25,
    paddingLeft: 15,
    paddingTop: 25,
    width: 1000,
  },
  linkStyle: {
    marginRight: 15,
  },
  vendorLinksSectionStyle: {
    marginTop: 10,
  },
};

export default BookDetailCard;
